import { Colors } from '../../config';
import { EventTypes, GameMode, PopupOpeningTypes } from '../../global.d';
import {
  setGameMode,
  setIsContinueAutoSpinsAfterFeature,
  setIsOpenAutoplayPopup,
  setIsOpenBetSettingsPopup,
  setIsOpenHistoryPopup,
  setIsOpenInfoPopup,
  setIsPopupOpened,
  setIsPopupOpeningInProgress,
} from '../../gql/cache';
import { calcPercentage, isRegularMode } from '../../utils';
import { eventManager } from '../config';
import { UiButton } from '../ui/uiButton';

class BetBtn extends UiButton {
  private isAutoSpins = false;

  private isSpinInProgress = false;

  private isSlotBusy = false;

  private isFreeSpinsWin = false;

  private gameMode = setGameMode();

  constructor() {
    super('bet');
    this.interactive = !this.isDisabled;
    this.btn.tint = Colors.GAME_COLOR;
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  private get isFreeRoundBonus(): boolean {
    return this.gameMode === GameMode.FREE_ROUND_BONUS;
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    this.gameMode = settings.mode;
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.OPEN_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREESPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
  };

  private handleDisable = (): void => {
    this.setDisable(
      !isRegularMode(this.gameMode) ||
        this.isFreeRoundBonus ||
        this.isAutoSpins ||
        this.isSpinInProgress ||
        this.isSlotBusy ||
        this.isFreeSpinsWin ||
        setIsPopupOpened() ||
        setIsContinueAutoSpinsAfterFeature(),
    );
  };

  private handleClick = (): void => {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.BET_SETTINGS_POPUP);

      setIsOpenBetSettingsPopup(!setIsOpenBetSettingsPopup());
      setIsOpenAutoplayPopup(false);
      setIsOpenInfoPopup(false);
      setIsOpenHistoryPopup(false);
      setIsPopupOpened(false);
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);

      setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  };

  public override handlePosition(): void {
    this.btn.anchor.set(1, 1);
    let size = calcPercentage(this.applicationSize.width, 7.5);
    const gap = calcPercentage(this.applicationSize.width, 1.25);
    let x = this.applicationSize.width - gap;
    let y = this.applicationSize.height - gap;
    this.setSize(size);

    if (this.isPortraitMode) {
      size = calcPercentage(this.applicationSize.height, 7.5);
      x = this.applicationSize.width - gap;
      y = this.applicationSize.height - gap;
      this.setSize(size);
    }

    this.x = x;
    this.y = y;
  }
}

export default BetBtn;
